@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.page__404-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
   @include gt-phone {
    // TODO: add specifications for devices that are greater than phones
    // example: .content { ... }
  }
}
